export const SUPER = "SUPER";

// Temporary role for Nathalie and the production team
export const PROD = "PROD";

export const PROD_TEAM_USERNAME = "user";

export const APPLICATION = "APPLICATION";
export const APPLICATION_GET = "APPLICATION_GET";

export const MULTI_APPLICATION = "MULTI_APPLICATION";

export const STORE_PUT = "STORE_PUT";

export const PARTNER = "PARTNER";
export const PARTNER_ONLINE_GET = "PARTNER_ONLINE_GET";
export const PARTNER_OFFLINE_GET = "PARTNER_OFFLINE_GET";
export const PARTNER_EBON_GET = "PARTNER_EBON_GET";
export const PARTNER_POST = "PARTNER_POST";
export const PARTNER_PUT = "PARTNER_PUT";
export const PARTNER_CATEGORY_PUT = "PARTNER_CATEGORY_PUT";
export const PARTNER_CATEGORY_GET = "PARTNER_CATEGORY_GET";
export const PARTNER_DELETE = "PARTNER_DELETE";

export const PARTNER_PRODUCTION_COMMENT = "PARTNER_PRODUCTION_COMMENT";
export const PARTNER_PRODUCTION_COMMENT_GET = "PARTNER_PRODUCTION_COMMENT_GET";

export const PARTNER_URL_PLATFORM = "PARTNER_URL_PLATFORM";
export const PARTNER_URL_PLATFORM_GET = "PARTNER_URL_PLATFORM_GET";
export const PARTNER_URL_PLATFORM_POST = "PARTNER_URL_PLATFORM_POST";
export const PARTNER_URL_PLATFORM_DELETE = "PARTNER_URL_PLATFORM_DELETE";

export const PARTNER_TOOLBAR = "PARTNER_TOOLBAR";
export const PARTNER_TOOLBAR_GET = "PARTNER_TOOLBAR_GET";
export const PARTNER_TOOLBAR_PUT = "PARTNER_TOOLBAR_PUT";

export const PARTNER_HISTORY = "PARTNER_HISTORY";

export const PARTNER_IMAGE_POST = "PARTNER_IMAGE_POST";

export const PARTNER_EXCLUSIVE_SCHEDULING = "PARTNER_EXCLUSIVE_SCHEDULING";
export const PARTNER_EXCLUSIVE_SCHEDULING_GET = "PARTNER_EXCLUSIVE_SCHEDULING_GET";

export const PARTNER_NAME_MATCHING = "PARTNER_NAME_MATCHING";
export const PARTNER_NAME_MATCHING_GET = "PARTNER_NAME_MATCHING_GET";
export const PARTNER_NAME_MATCHING_INSERT = "PARTNER_NAME_MATCHING_POST";
export const PARTNER_NAME_MATCHING_DELETE = "PARTNER_NAME_MATCHING_DELETE";

export const OFFER = "OFFER";
export const OFFER_ONLINE_GET = "OFFER_ONLINE_GET";
export const OFFER_OFFLINE_GET = "OFFER_OFFLINE_GET";
export const OFFER_EBON_GET = "OFFER_EBON_GET";
export const OFFER_POST = "OFFER_POST";
export const OFFER_PUT = "OFFER_PUT";
export const OFFER_DELETE = "OFFER_DELETE";
export const OFFER_ONLINE_DELETE = "OFFER_ONLINE_DELETE";
export const OFFER_OFFLINE_DELETE = "OFFER_OFFLINE_DELETE";
export const OFFER_EBON_DELETE = "OFFER_EBON_DELETE";
export const OFFER_EBUYCLUB_GET = "OFFER_EBUYCLUB_GET";

export const CASHBACK_RATE = "CASHBACK_RATE";
export const CASHBACK_RATE_TEMP = "CASHBACK_RATE_TEMP";

export const PURCHASE = "PURCHASE";
export const PURCHASE_GET = "PURCHASE_GET";
export const PURCHASE_PUT = "PURCHASE_PUT";
export const PURCHASE_POST = "PURCHASE_POST";
export const PURCHASE_DELETE = "PURCHASE_DELETE";
export const PURCHASE_INDICATORS = "PURCHASE_INDICATORS";
export const PURCHASE_VALIDATION_PUT = "PURCHASE_VALIDATION_PUT";

export const PLATFORM = "PLATFORM";
export const PLATFORM_GET = "PLATFORM_GET";

export const PLATFORM_CLAIM = "PLATFORM_CLAIM";
export const PLATFORM_CLAIM_GET = "PLATFORM_CLAIM_GET";
export const PLATFORM_CLAIM_POST = "PLATFORM_CLAIM_POST";

export const MEA = "MEA";
export const MEA_GET = "MEA_GET";
export const MEA_POST = "MEA_POST";
export const MEA_PUT = "MEA_PUT";
export const MEA_DELETE = "MEA_DELETE";
export const MEA_MULTIPLE_IMAGE_POST = "MEA_MULTIPLE_IMAGE_POST";

export const MEMBER = "MEMBER";
export const MEMBER_GET = "MEMBER_GET";
export const MEMBER_POST = "MEMBER_POST";
export const MEMBER_PUT = "MEMBER_PUT";
export const MEMBER_LOG_AS = "MEMBER_LOG_AS";

export const MEMBER_PIN_CODE = "MEMBER_PIN_CODE";
export const MEMBER_PIN_CODE_GET = "MEMBER_PIN_CODE_GET";
export const MEMBER_PIN_CODE_DELETE = "MEMBER_PIN_CODE_DELETE";

export const MEMBER_PASSWORD_RESET = "MEMBER_PASSWORD_RESET";

export const SPONSOR = "SPONSOR";
export const SPONSOR_GET = "SPONSOR_GET";
export const SPONSOR_PUT = "SPONSOR_PUT";
export const SPONSOR_POST = "SPONSOR_POST";

export const AFFILIATE_TAG = "AFFILIATE_TAG";
export const AFFILIATE_TAG_GET = "AFFILIATE_TAG_GET";

export const MEMBER_CHANNEL = "MEMBER_CHANNEL";
export const MEMBER_CHANNEL_GET = "MEMBER_CHANNEL_GET";

export const TRACKING = "TRACKING";
export const TRACKING_GET = "TRACKING_GET";

export const PAYMENT_REQUEST = "PAYMENT_REQUEST";
export const PAYMENT_REQUEST_GET = "PAYMENT_REQUEST_GET";
export const PAYMENT_REQUEST_VALIDATION = "PAYMENT_REQUEST_VALIDATION";
export const PAYMENT_REQUEST_GENERATION = "PAYMENT_REQUEST_GENERATION";
export const PAYMENT_REQUEST_GLOBAL_MB_VALIDATION = "PAYMENT_REQUEST_GLOBAL_MB_VALIDATION";

export const TOOLBAR_NOTIFICATION = "TOOLBAR_NOTIFICATION";
export const TOOLBAR_NOTIFICATION_GET = "TOOLBAR_NOTIFICATION_GET";
export const TOOLBAR_NOTIFICATION_POST = "TOOLBAR_NOTIFICATION_POST";
export const TOOLBAR_NOTIFICATION_PUT = "TOOLBAR_NOTIFICATION_PUT";
export const TOOLBAR_NOTIFICATION_DELETE = "TOOLBAR_NOTIFICATION_DELETE";

export const COMMISSION_BOOST = "COMMISSION_BOOST";
export const COMMISSION_BOOST_GET = "COMMISSION_BOOST_GET";
export const COMMISSION_BOOST_PUT = "COMMISSION_BOOST_PUT";
export const COMMISSION_BOOST_DELETE = "COMMISSION_BOOST_DELETE";

export const EBON = "EBON";
export const EBON_GET = "EBON_GET";
export const EBON_ORDER_BALANCE_GET = "EBON_ORDER_BALANCE_GET";

export const EBON_GROUP = "EBON_GROUP";
export const EBON_GROUP_GET = "EBON_GROUP_GET";
export const EBON_GROUP_POST = "EBON_GROUP_POST";
export const EBON_GROUP_PUT = "EBON_GROUP_PUT";
export const EBON_GROUP_DELETE = "EBON_GROUP_DELETE";

export const EBON_ORDER = "EBON_ORDER";
export const EBON_ORDER_GET = "EBON_ORDER_GET";
export const EBON_ORDER_PUT = "EBON_ORDER_PUT";
export const EBON_ORDER_POST_INTERNAL = "EBON_ORDER_POST_INTERNAL";
export const EBON_ORDER_POST_EBUYCARD = "EBON_ORDER_POST_EBUYCARD";
export const EBON_ORDER_HISTORY = "EBON_ORDER_HISTORY";
export const EBON_CODE = "EBON_CODE";
export const EBON_ORDER_URL_GET = "EBON_ORDER_URL_GET";
export const EBON_ORDER_BILL_GET = "EBON_ORDER_BILL_GET";
export const EBON_ORDER_HISTORY_GET = "EBON_ORDER_HISTORY_GET";

export const EBON_DISPLAY_ORDER = "EBON_DISPLAY_ORDER";
export const EBON_DISPLAY_ORDER_GET = "EBON_DISPLAY_ORDER_GET";
export const EBON_DISPLAY_ORDER_POST = "EBON_DISPLAY_ORDER_POST";

export const EBON_FAQ_PURCHASE_STEP = "EBON_FAQ_PURCHASE_STEP";
export const EBON_FAQ_PURCHASE_STEP_GET = "EBON_FAQ_PURCHASE_STEP_GET";
export const EBON_FAQ_PURCHASE_STEP_POST = "EBON_FAQ_PURCHASE_STEP_POST";

export const EBON_COMMISSION = "EBON_COMMISSION";
export const EBON_COMMISSION_GET = "EBON_COMMISSION_GET";
export const EBON_COMMISSION_POST = "EBON_COMMISSION_POST";
export const EBON_COMMISSION_PUT = "EBON_COMMISSION_PUT";
export const EBON_ORIGIN = "EBON_ORIGIN";
export const EBON_COMMISSION_FILTER = "EBON_COMMISSION_FILTER";

export const EBON_API_STOCK = "EBON_API_STOCK";
export const EBON_API_STOCK_GET = "EBON_API_STOCK_GET";
export const EBON_API_STOCK_PUT = "EBON_API_STOCK_PUT";

export const EBON_EXPORT = "EBON_EXPORT";

export const EBON_EXPIRED = "EBON_EXPIRED";

export const EBON_EBUYCARD_HISTORY = "EBON_EBUYCARD_HISTORY";
export const EBON_EBUYCARD_HISTORY_GET = "EBON_EBUYCARD_HISTORY_GET";

export const EBON_PAYMENT_CONFIG = "EBON_PAYMENT_CONFIG";
export const EBON_PAYMENT_CONFIG_GET = "EBON_PAYMENT_CONFIG_GET";
export const EBON_PAYMENT_CONFIG_PUT = "EBON_PAYMENT_CONFIG_PUT";

export const PARTNER_EBON_IMAGE_PUT = "PARTNER_EBON_IMAGE_PUT";

export const EBON_BATCH_ORDER_POST = "EBON_BATCH_ORDER_POST";
export const EBON_BATCH_ORDER_GET = "EBON_BATCH_ORDER_GET";
export const EBON_BATCH_ORDER = "EBON_BATCH_ORDER";

export const SEO = "SEO";
export const SEO_GET = "SEO_GET";
export const SEO_PUT = "SEO_PUT";
export const SEO_POST = "SEO_POST";

export const COMMUNICATION = "COMMUNICATION";
export const COMMUNICATION_GET = "COMMUNICATION_GET";
export const COMMUNICATION_POST = "COMMUNICATION_POST";
export const COMMUNICATION_DELETE = "COMMUNICATION_DELETE";

export const APPLICATION_CLIENT_REQUEST = "APPLICATION_CLIENT_REQUEST";
export const APPLICATION_CLIENT_REQUEST_GET = "APPLICATION_CLIENT_REQUEST_GET";
export const APPLICATION_CLIENT_REQUEST_POST = "APPLICATION_CLIENT_REQUEST_POST";
export const APPLICATION_CLIENT_REQUEST_PUT = "APPLICATION_CLIENT_REQUEST_PUT";
export const APPLICATION_CLIENT_REQUEST_EXPORT = "APPLICATION_CLIENT_REQUEST_EXPORT";

export const PMI = "PMI";
export const PMI_GET = "PMI_GET";

export const SALES_REPRESENTATIVE = "SALES_REPRESENTATIVE";
export const SALES_REPRESENTATIVE_GET = "SALES_REPRESENTATIVE_GET";
export const SALES_REPRESENTATIVE_PUT = "SALES_REPRESENTATIVE_PUT";

export const EMPLOYEE = "EMPLOYEE";
export const EMPLOYEE_GET = "EMPLOYEE_GET";

export const STORE = "STORE";
export const STORE_GET = "STORE_GET";
export const STORE_POST = "STORE_POST";
export const STORE_DELETE = "STORE_DELETE";

export const COMPETITOR_PARTNER = "COMPETITOR_PARTNER";
export const COMPETITOR_PARTNER_GET = "COMPETITOR_PARTNER_GET";
export const COMPETITOR_PARTNER_PUT = "COMPETITOR_PARTNER_PUT";

export const BILLING_PARAMS = "BILLING_PARAMS";
export const BILLING_PARAMS_GET = "BILLING_PARAMS_GET";

export const INVOICE = "INVOICE";
export const INVOICE_GET = "INVOICE_GET";

export const COUPON_SERVICE_PROVIDER = "COUPON_SERVICE_PROVIDER";

export const COUPON = "COUPON";
export const COUPON_GET = "COUPON_GET";
export const COUPON_STATS_GET = "COUPON_STATS_GET";
export const COUPON_PUT = "COUPON_PUT";
export const COUPON_POST = "COUPON_POST";
export const COUPON_DELETE = "COUPON_DELETE";

export const CUSTOMER_SERVICE = "CUSTOMER_SERVICE";
export const CUSTOMER_SERVICE_GET = "CUSTOMER_SERVICE_GET";
export const CUSTOMER_SERVICE_PUT = "CUSTOMER_SERVICE_PUT";

export const DAILY_NEWS = "DAILY_NEWS";
export const DAILY_NEWS_GET = "DAILY_NEWS_GET";
export const DAILY_NEWS_PUT = "DAILY_NEWS_PUT";

export const OFFLINE_BILLING = "OFFLINE_BILLING";

export const APPLICATION_BUDGET = "APPLICATION_BUDGET";
export const APPLICATION_BUDGET_GET = "APPLICATION_BUDGET_GET";
export const APPLICATION_BUDGET_POST = "APPLICATION_BUDGET_POST";

export const OFFER_CASHBACK_OVERFLOW = "OFFER_CASHBACK_OVERFLOW";

export const GENERIC_PICTURE_UPLOAD = "GENERIC_PICTURE_UPLOAD";

export const GALLERY_PARTNER = "GALLERY_PARTNER";
export const GALLERY_PARTNER_GET = "GALLERY_PARTNER_GET";
export const GALLERY_PARTNER_DELETE = "GALLERY_PARTNER_DELETE";
export const GALLERY_PARTNER_PUT = "GALLERY_PARTNER_PUT";
export const GALLERY_PARTNER_POST = "GALLERY_PARTNER_POST";

export const CATALINA_REWARD = "CATALINA_REWARD";
export const CATALINA_REWARD_GET = "CATALINA_REWARD_GET";

export const FAQ = "FAQ";
export const FAQ_GET = "FAQ_GET";

export const PLATFORM_IMPORT = "PLATFORM_IMPORT";

export const ALERT_RECIPIENT = "ALERT_RECIPIENT";

export const REVIEW = "REVIEW";
export const REVIEW_GET = "REVIEW_GET";

export const KICKBACK = "KICKBACK";
export const KICKBACK_GET = "KICKBACK_GET";

export const ONLINE_COMPETITION = "ONLINE_COMPETITION";
export const ONLINE_COMPETITION_GET = "ONLINE_COMPETITION_GET";
export const ONLINE_COMPETITION_POST = "ONLINE_COMPETITION_POST";
export const ONLINE_COMPETITION_PUT = "ONLINE_COMPETITION_PUT";
export const ONLINE_COMPETITION_DELETE = "ONLINE_COMPETITION_DELETE";

export const ALO_TRANSACTION = "ALO_TRANSACTION";
export const ALO_TRANSACTION_GET = "ALO_TRANSACTION_GET";
